import { Controller } from "@hotwired/stimulus";
import { debounce } from "../plugins/debounce";

const DEBOUNCE_TIME = 100;

// Connects to data-controller="pauses"
export default class extends Controller {
  static values = {
    path: String,
  };

  static targets = ["input"];

  initialize() {
    this.debounceRefresh = debounce(() => this.refresh(), DEBOUNCE_TIME);
  }

  connect() {
    this.frame = this.element.closest("turbo-frame");
  }

  refresh() {
    const url = new URL(window.location.origin);
    url.pathname = this.pathValue;

    if (this.hasInputTarget) {
      url.searchParams.set(this.inputTarget.name, this.inputTarget.value);
      this.frame.src = url;
    }
  }
}
