import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="popover"
export default class extends Controller {
  static targets = ["popoverable", "trigger"];

  toggle() {
    if (!this.hasPopoverableTarget) return;

    if (this.popoverableTarget.classList.contains("d-none")) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    if (this.hasPopoverableTarget) {
      this.popoverableTarget.classList.remove("d-none");
    }

    if (this.hasTriggerTarget) {
      this.triggerTarget.classList.add("active");
    }
  }

  close() {
    if (this.hasPopoverableTarget) {
      this.popoverableTarget.classList.add("d-none");
    }

    if (this.hasTriggerTarget) {
      this.triggerTarget.classList.remove("active");
    }
  }

  tryClosing(event) {
    if (event.type === "keydown" && event.key === "Escape") return this.close();

    // Ignore event if clicked within element
    if (this.element !== event.target && !this.element.contains(event.target))
      return this.close(event);
  }
}
