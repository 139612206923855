import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="frame-popover"
export default class extends Controller {
  static values = {
    path: String,
    frameId: String,
  };

  connect() {
    this.frame = this.element.querySelector(`turbo-frame#${this.frameIdValue}`);
  }

  toggle() {
    if (!this.frame) return;

    if (this.frame.src) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    if (!this.frame) return;

    // We can load a turbo-frame programatically by setting its src attribute
    this.frame.src = this.pathValue;
  }

  close() {
    if (!this.frame) return;

    this.frame.innerText = "";
    this.frame.removeAttribute("src");
  }

  tryClosing(event) {
    if (event.type === "keydown" && event.key === "Escape") return this.close();

    // Ignore event if clicked within element
    if (this.element !== event.target && !this.element.contains(event.target))
      return this.close();
  }
}
