import { Controller } from "@hotwired/stimulus";
import {
  showCrisp,
  hideCrisp,
  showAxeptio,
  hideAxeptio,
} from "../plugins/trackers";
import { showOverlay, hideOverlay } from "../plugins/overlay";
import { debounce } from "../plugins/debounce";

const DEBOUNCE_TIME = 300;

// Connects to data-controller="products-filter"
export default class extends Controller {
  static targets = [
    "checkbox",
    "filters",
    "form",
    "search",
    "counter",
    "wrapper",
    "group",
  ];

  connect() {
    window.addEventListener("hashchange", (event) => this.#onHashChange(event));
  }

  disconnect() {
    window.removeEventListener("hashchange", (event) =>
      this.#onHashChange(event),
    );
  }

  initialize() {
    this.debounceSubmit = debounce(
      () => this.updateCounterAndSubmit(),
      DEBOUNCE_TIME,
    );
  }

  toggleFilters() {
    this.filtersTargets.forEach((target) => target.classList.toggle("d-none"));
  }

  submit() {
    showOverlay();
    this.formTarget.requestSubmit();
  }

  resetSearch() {
    this.searchTarget.value = "";
  }

  resetSearchAndSubmit() {
    this.searchTarget.value = "";
    this.updateCounterAndSubmit();
  }

  updateCounterAndSubmit() {
    if (this.hasCounterTarget) {
      const checkboxSizes = this.checkboxTargets.filter(
        ({ checked }) => checked,
      ).length;
      const searchSize = this.searchTarget.value === "" ? 0 : 1;

      const size = checkboxSizes + searchSize;

      if (size > 0) {
        this.counterTarget.textContent = `${size}`;
        this.counterTarget.classList.remove("d-none");
      } else {
        this.counterTarget.classList.add("d-none");
      }
    }

    this.submit();
  }

  showFullScreen() {
    this.wrapperTarget.classList.add("filter-full-screen");

    hideCrisp();
    hideAxeptio();
  }

  hideFullScreen() {
    this.wrapperTarget.classList.remove("filter-full-screen");

    showCrisp();
    showAxeptio();
  }

  clear() {
    this.checkboxTargets.forEach((el) => {
      el.checked = false;
    });
    this.resetSearch();
    this.updateCounterAndSubmit();
  }

  hideOverlay() {
    hideOverlay();
  }

  uncheck(event) {
    const button = event.target.closest("button");

    if (!button) return;

    const id = button.dataset.value;
    const param = button.dataset.param;

    const input = this.checkboxTargets.find(
      ({ value, name }) => value === id && name === `${param}[]`,
    );

    if (input) {
      input.checked = false;
      this.updateCounterAndSubmit();
    }
  }

  #onHashChange() {
    this.#updateBtnColors();
    this.#scrollToProductsTop();
  }

  #updateBtnColors() {
    this.groupTargets.forEach((el) => {
      const groupName = el.dataset.param;

      if (window.location.search.includes(groupName)) {
        el.classList.remove("outline");
      } else {
        el.classList.add("outline");
      }
    });
  }

  #scrollToProductsTop() {
    this.element.scrollIntoView();
  }
}
